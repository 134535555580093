import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {toast} from 'react-toastify';
import withRouter from 'app/utils/withRouter';
import HelmetMaker from 'app/utils/Helmet';
import {Visibility, VisibilityOff} from '@mui/icons-material';

// Redux Actions
import {LOGIN_ACTION, CLEAR_AUTH_ACTION} from 'app/actions/authAction';

// MUI
import {
  Box,
  Button,
  Checkbox,
  CssBaseline,
  FormControlLabel,
  Grid,
  Typography,
  IconButton,
} from '@mui/material';

// Form Validation
import {ValidatorForm, TextValidator} from 'react-material-ui-form-validator';

function mapStateToProps(state) {
  return {
    GET_AUTH_STATE: state.AUTH_REDUCER.Auth,
    GET_ERRORS_STATE: state.ERROR_REDUCER.Errors,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    DISPATCH_LOGIN: (payload) => dispatch(LOGIN_ACTION(payload)),
    DISPATCH_CLEAR_AUTH: () => dispatch(CLEAR_AUTH_ACTION()),
  };
}

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Payload: {
        email: '',
        password: '',
        remember: false,
        agreeTerms: false,
      },
      errors: {
        email: '',
        password: '',
      },
      submitClicked: false,
      showPassword: false,
    };

    this.HandleInputChange = this.HandleInputChange.bind(this);
    this.HandleSubmit = this.HandleSubmit.bind(this);
    this.HandleCheckboxChange = this.HandleCheckboxChange.bind(this);
    this.validateForm = this.validateForm.bind(this);
    this.handleTogglePasswordVisibility = this.handleTogglePasswordVisibility.bind(this);
  }

  componentDidMount() {
    const {GET_ERRORS_STATE, DISPATCH_CLEAR_AUTH, GET_AUTH_STATE, navigate} = this.props;

    // Clear any prior errors if present
    if (GET_ERRORS_STATE.length !== 0) {
      DISPATCH_CLEAR_AUTH();
    }

    // If user is already authenticated, redirect them
    const {isAuthenticated, token} = GET_AUTH_STATE;
    if (isAuthenticated && token !== null) {
      navigate('/companies');
    }
  }

  componentDidUpdate(prevProps) {
    const {GET_ERRORS_STATE, DISPATCH_CLEAR_AUTH, GET_AUTH_STATE, navigate} = this.props;

    // If errors come in, clear auth states
    if (GET_ERRORS_STATE.length !== 0) {
      DISPATCH_CLEAR_AUTH();
    }

    // Redirect upon successful login
    if (prevProps.GET_AUTH_STATE !== GET_AUTH_STATE) {
      const {isAuthenticated, token} = GET_AUTH_STATE;
      if (isAuthenticated && token !== null) {
        navigate('/companies');
      }
    }
  }

  handleTogglePasswordVisibility = () => {
    this.setState((prevState) => ({
      showPassword: !prevState.showPassword,
    }));
  };

  HandleInputChange(event) {
    const {name, value} = event.target;
    const {errors} = this.state;

    if (name === 'email' && this.state.submitClicked) {
      const emailValid = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value);
      errors.email = emailValid ? '' : 'Email is not valid';
    }

    if (name === 'password') {
      errors.password = value.length < 8 ? 'Password must be at least 8 characters long' : '';
    }

    this.setState((prevState) => ({
      Payload: {
        ...prevState.Payload,
        [name]: value,
      },
      errors,
    }));
  }

  HandleCheckboxChange() {
    toast.dismiss();
    this.setState((prevState) => ({
      Payload: {
        ...prevState.Payload,
        agreeTerms: !prevState.Payload.agreeTerms,
      },
    }));
  }

  validateForm() {
    let isValid = true;
    const errors = {email: '', password: ''};

    if (!this.state.Payload.email) {
      errors.email = 'Email is required';
      isValid = false;
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(this.state.Payload.email)) {
      errors.email = 'Email is not valid';
      isValid = false;
    }

    if (!this.state.Payload.password) {
      errors.password = 'Password is required';
      isValid = false;
    } else if (this.state.Payload.password.length < 8) {
      errors.password = 'Password must be at least 8 characters long';
      isValid = false;
    }

    this.setState({errors});
    return isValid;
  }

  async HandleSubmit(event) {
    event.preventDefault();
    this.setState({submitClicked: true});

    if (this.validateForm()) {
      const {DISPATCH_LOGIN} = this.props;
      // console.log('LOGIN Payload', this.state.Payload);
      const response = await DISPATCH_LOGIN(this.state.Payload);

      if (!response) {
        console.log('Connection lost to server. Please refresh the page and try again.', response);
        toast.error('Connection lost to server. Please refresh the page and try again.');
      } else if (response.status === 200) {
        toast.success('Sign in successful. Welcome!');
      } else if (response.status === 403) {
        toast.error('Incorrect password');
      } else if (response.status === 206) {
        toast.error(
          "You haven't set up a password yet. Please follow the instructions in the email we just sent you."
        );
      } else {
        toast.error('Account does not exist');
      }
    } else {
      toast.error('Please correct the errors in the form.');
    }
  }

  render() {
    const {navigate} = this.props;
    const helmetload = {
      title: 'Log in | Find Me Sales',
      description: 'Sign in to continue using the Find Me Sales service.',
      url: 'https://findmesales.com/login',
      keywords: 'Leads, Sales, sign in, login',
    };

    return (
      <Fragment>
        <HelmetMaker props={helmetload} />

        {/* Outer container mimicking the style from the 'Register' snippet */}
        <div
          style={{
            background: '#fafafa',
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            alignItems: 'center',
            padding: '60px 20px',
          }}>
          <CssBaseline />
          {/* Box to contain the login form and styling */}
          <Box
            sx={{
              maxWidth: '640px',
              width: '100%',
              bgcolor: '#fff',
              marginTop: '38px',
              boxShadow: '0 8px 60px rgba(0, 0, 0, 0.15)',
              borderRadius: 4,
              p: {xs: 2, sm: 4},
              textAlign: 'center',
            }}>
            <Typography
              variant="h1"
              sx={{
                fontWeight: '600 !important',
                fontSize: {xs: '22px', sm: '34px'},
                marginBottom: '50px',
                color: '#000',
                lineHeight: '1.4',
                letterSpacing: '-1px',
              }}>
              Sign in
            </Typography>

            <ValidatorForm
              id="loginform"
              ref={(form) => {
                this.form = form;
              }}
              onError={(errors) => {
                if (typeof window !== 'undefined') {
                  window.scrollTo(0, 0);
                  toast.error(errors);
                }
              }}
              onSubmit={this.HandleSubmit}>
              <Grid container spacing={3} justifyContent="center">
                <Grid item xs={12} style={{paddingBottom: '14px'}}>
                  <TextValidator
                    label="Email Address"
                    onChange={this.HandleInputChange}
                    name="email"
                    value={this.state.Payload.email}
                    error={!!this.state.errors.email}
                    helperText={this.state.errors.email}
                    style={{height: '60px'}}
                    sx={{
                      '& .MuiInputLabel-shrink': {
                        // marginLeft: '-6px',
                        paddingRight: '16px',
                        marginTop: '-4px',
                        backgroundColor: 'white',
                        borderRadius: '25px',
                      },
                      '& .MuiFormHelperText-root': {
                        zIndex: 2,
                        // marginTop: '-11px',
                        paddingLeft: '14px',
                      },
                      '& .MuiFormLabel-root': {
                        paddingTop: '10px',
                        paddingLeft: '14px',
                      },
                      '& .MuiInputBase-root': {
                        borderRadius: '46px',
                        paddingRight: '8px',
                        paddingTop: '9px',
                        paddingBottom: '9px',
                        paddingLeft: '8px',
                        fontSize: '14px',
                        backgroundColor: 'white',
                      },
                    }}
                    fullWidth
                    // autoComplete="email"
                  />
                </Grid>
                <Grid item xs={12} sx={{position: 'relative'}}>
                  <TextValidator
                    label="Password"
                    type={this.state.showPassword ? 'text' : 'password'}
                    onChange={this.HandleInputChange}
                    name="password"
                    value={this.state.Payload.password}
                    error={!!this.state.errors.password}
                    helperText={this.state.errors.password}
                    sx={{
                      '& .MuiInputLabel-shrink': {
                        paddingRight: '16px',
                        marginTop: '-4px',
                        backgroundColor: 'white',
                        borderRadius: '25px',
                      },
                      '& .MuiFormHelperText-root': {
                        zIndex: 2,
                        paddingLeft: '14px',
                      },
                      '& .MuiFormLabel-root': {
                        paddingTop: '10px',
                        paddingLeft: '14px',
                      },
                      '& .MuiInputBase-root': {
                        borderRadius: '46px',
                        paddingTop: '9px',
                        paddingBottom: '9px',
                        paddingLeft: '8px',
                        paddingRight: '8px', // Removed excessive padding to avoid shifting
                        fontSize: '14px',
                        backgroundColor: 'white',
                      },
                      '& .MuiInputBase-input': {
                        paddingRight: '50px', // Ensure text does not overlap with the floating icon
                      },
                    }}
                    fullWidth
                    InputProps={{
                      endAdornment: (
                        <IconButton
                          onClick={this.handleTogglePasswordVisibility}
                          sx={{
                            position: 'absolute',
                            right: '14px', // Proper spacing
                            top: '50%',
                            transform: 'translateY(-50%)',
                            zIndex: 2, // Keeps it on top
                            backgroundColor: 'rgba(255, 255, 255, 0.7)', // Blends in
                            borderRadius: '50%',
                            padding: '6px',
                            '&:hover': {
                              backgroundColor: 'rgba(255, 255, 255, 0.9)',
                            },
                          }}>
                          {this.state.showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={12} textAlign="left" style={{paddingLeft: '48px'}}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={this.state.Payload.agreeTerms}
                        onChange={this.HandleCheckboxChange}
                        color="primary"
                      />
                    }
                    label="Remember me"
                  />
                </Grid>

                <Grid item xs={12}>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    sx={{
                      borderRadius: '46px',
                      textTransform: 'none',
                      fontWeight: 500,
                      backgroundColor: '#003671',
                      color: '#fff',
                      fontSize: '18px',
                      boxShadow: 'none',
                      padding: '14px 24px',
                      width: '100%',
                      '&:hover': {
                        backgroundColor: '#00285a',
                      },
                    }}>
                    Sign In
                  </Button>
                </Grid>
              </Grid>
            </ValidatorForm>

            {/* Links for Forgot Password and Sign Up */}
            <Grid container sx={{margin: '48px 0px 24px'}}>
              <Grid item xs style={{textAlign: 'left', paddingLeft: '24px'}}>
                <Typography
                  variant="body2"
                  style={{fontSize: '0.8rem'}}
                  sx={{cursor: 'pointer', textDecoration: 'underline'}}
                  onClick={() => {
                    navigate({
                      pathname: '/password/forgot/',
                    });
                  }}>
                  Forgot password?
                </Typography>
              </Grid>
              <Grid item style={{paddingRight: '24px'}}>
                <Typography
                  variant="body2"
                  style={{fontSize: '0.8rem'}}
                  sx={{cursor: 'pointer', textDecoration: 'underline'}}
                  onClick={() => {
                    navigate('/pricing/?d=Monthly');
                  }}>
                  Don&apos;t have an account? Sign Up
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </div>
      </Fragment>
    );
  }
}

Login.propTypes = {
  DISPATCH_LOGIN: PropTypes.func.isRequired,
  DISPATCH_CLEAR_AUTH: PropTypes.func.isRequired,
  GET_ERRORS_STATE: PropTypes.array.isRequired,
  GET_AUTH_STATE: PropTypes.object.isRequired,
  navigate: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Login));

// /* eslint-disable jsx-a11y/anchor-is-valid */
// import React, { Component, Fragment } from 'react';
// import PropTypes from 'prop-types';
// import { connect } from 'react-redux';
// import Link from '@mui/material/Link';
// import { LOGIN_ACTION, CLEAR_AUTH_ACTION } from 'app/actions/authAction';
// import Button from '@mui/material/Button';
// import CssBaseline from '@mui/material/CssBaseline';
// import FormControlLabel from '@mui/material/FormControlLabel';
// import Checkbox from '@mui/material/Checkbox';
// import { TextField } from '@mui/material';
// import Grid from '@mui/material/Grid';
// import Typography from '@mui/material/Typography';
// import HelmetMaker from 'app/utils/Helmet';
// import Container from '@mui/material/Container';
// import { toast } from 'react-toastify';
// import { ValidatorForm } from 'react-material-ui-form-validator';
// import withRouter from 'app/utils/withRouter';

// function mapStateToProps(state) {
//   return {
//     GET_AUTH_STATE: state.AUTH_REDUCER.Auth,
//     GET_ERRORS_STATE: state.ERROR_REDUCER.Errors,
//   };
// }

// function mapDispatchToProps(dispatch) {
//   return {
//     DISPATCH_LOGIN: (payload) => dispatch(LOGIN_ACTION(payload)),
//     DISPATCH_CLEAR_AUTH: () => dispatch(CLEAR_AUTH_ACTION()),
//   };
// }

// const scrollTop = () => {
//   if (typeof window !== 'undefined') {
//     window.scrollTo({ top: 0, behavior: 'smooth' });
//   }
// };

// class Login extends Component {
//   constructor(props) {
//     super(props);
//     this.state = {
//       Payload: {
//         email: '',
//         password: '',
//         remember: false,
//         agreeTerms: false,
//       },
//       errors: {
//         email: '',
//         password: '',
//       },
//       submitClicked: false,
//     };
//     // bind event handlers in class components.
//     this.HandleInputChange = this.HandleInputChange.bind(this);
//     this.HandleSubmit = this.HandleSubmit.bind(this);
//     this.HandleCheckboxChange = this.HandleCheckboxChange.bind(this);
//     this.validateForm = this.validateForm.bind(this);
//   }

//   componentDidMount() {
//     const { GET_ERRORS_STATE, DISPATCH_CLEAR_AUTH, GET_AUTH_STATE, navigate } =
//       this.props;
//     if (GET_ERRORS_STATE.length !== 0) {
//       DISPATCH_CLEAR_AUTH();
//     }
//     const { isAuthenticated, token } = GET_AUTH_STATE;
//     if (isAuthenticated && token !== null) {
//       navigate('/companies');
//     }
//   }

//   componentDidUpdate(prevState, prevProps) {
//     const { GET_ERRORS_STATE, DISPATCH_CLEAR_AUTH, GET_AUTH_STATE, navigate } =
//       this.props;
//     if (GET_ERRORS_STATE.length !== 0) {
//       // toast.error(GET_ERRORS_STATE[0]?.message ? GET_ERRORS_STATE[0].message : 'An error occurred, please try again');
//       DISPATCH_CLEAR_AUTH();
//     }
//     const { isAuthenticated, token } = GET_AUTH_STATE;
//     if (prevProps.GET_AUTH_STATE !== GET_AUTH_STATE) {
//       if (isAuthenticated && token !== null) {
//         // console.log('AUTHENTICATION CHANGE', prevProps.GET_AUTH_STATE, GET_AUTH_STATE);
//         navigate('/companies');
//       }
//     }
//   }

//   HandleInputChange(event) {
//     const { name, value } = event.target;
//     const { errors } = this.state;

//     // Validate email
//     if (name === 'email' && this.state.submitClicked) {
//       const emailValid = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value);
//       errors.email = emailValid ? '' : 'Email is not valid';
//     }

//     // Validate password
//     if (name === 'password') {
//       errors.password =
//         value.length < 8 ? 'Password must be at least 8 characters long' : '';
//     }

//     this.setState({
//       Payload: {
//         ...this.state.Payload,
//         [name]: value,
//       },
//       errors,
//     });
//   }

//   HandleCheckboxChange(event) {
//     toast.dismiss();
//     if (this.state.Payload.agreeTerms === false) {
//       this.setState({
//         Payload: {
//           ...this.state.Payload,
//           agreeTerms: true,
//         },
//       });
//     } else {
//       this.setState({
//         Payload: {
//           ...this.state.Payload,
//           agreeTerms: false,
//         },
//       });
//     }
//   }

//   validateForm() {
//     let isValid = true;
//     const errors = { email: '', password: '' };

//     // Email validation
//     if (!this.state.Payload.email) {
//       errors.email = 'Email is required';
//       isValid = false;
//     } else if (
//       !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(this.state.Payload.email)
//     ) {
//       errors.email = 'Email is not valid';
//       isValid = false;
//     }

//     // Password validation
//     if (!this.state.Payload.password) {
//       errors.password = 'Password is required';
//       isValid = false;
//     } else if (this.state.Payload.password.length < 8) {
//       errors.password = 'Password must be at least 8 characters long';
//       isValid = false;
//     }

//     this.setState({ errors });
//     return isValid;
//   }

//   async HandleSubmit(event) {
//     event.preventDefault();
//     this.setState({ submitClicked: true });

//     if (this.validateForm()) {
//       // Form is valid, proceed with submission logic
//       // ... your submit logic here

//       const { DISPATCH_LOGIN, navigate, GET_ERRORS_STATE } = this.props;
//       const response = await DISPATCH_LOGIN(this.state.Payload);
//       // console.log('LOGIN RESPONSE', response);
//       if (!response) {
//         toast.error(
//           'Connection lost to server. Please refresh the page and try again.'
//         );
//       } else if (response.status === 200) {
//         // this.props.location.state
//         //   ? navigate(this.props.location.state.lastpage)
//         //   : navigate('/companies');
//         toast.success('Sign in successful. Welcome!');
//       } else if (response.status === 403) {
//         toast.error('Incorrect password');
//       } else if (response.status === 206) {
//         toast.error(
//           "You haven't set up a password yet. Please follow the instructions in the email we just sent you."
//         );
//       } else {
//         // const {errors} = await response.json();
//         toast.error('Account does not exist');
//         // this.setState({
//         //   GET_ERRORS_STATE: Array.isArray(errors) ? [...errors] : [errors],
//         // });
//         scrollTop;
//       }
//     } else {
//       // Form is invalid, do not submit and possibly show an error message
//       toast.error('Please correct the errors in the form.');
//     }
//   }

//   render() {
//     const { navigate } = this.props;
//     const helmetload = {
//       title: 'Log in | Find Me Sales',
//       description: 'Sign in to continue using the Find Me Sales service.',
//       url: 'https://findmesales.com/login',
//       // image:
//       //   'https://storage.cloud.google.com/find-me-sales-bucket/assets/leadsearchhome.jpg',
//       keywords: 'Leads, Sales, sign in, login',
//     };
//     return (
//       <Fragment>
//         <HelmetMaker props={helmetload} />
//         <Container
//           className="login-container"
//           component="main"
//           maxWidth="sm"
//           style={{ paddingBottom: '25px' }}
//         >
//           <CssBaseline />
//           {/* <ScreenSearchDesktopIcon size="large" style={{fontSize: '200px', color: '#77DD77'}} /> */}
//           <Typography
//             className="loginheader"
//             component="h1"
//             variant="h1"
//             style={{ fontWeight: '600', paddingBottom: '54px' }}
//           >
//             Sign in
//           </Typography>
//           <ValidatorForm
//             id="loginform"
//             ref={(form) => {
//               this.form = form;
//             }}
//             onError={(errors) => {
//               if (typeof window !== 'undefined') {
//                 window.scrollTo(0, 260), toast.error(errors);
//               }
//             }}
//             onSubmit={this.HandleSubmit}
//           >
//             <Grid container spacing={2}>
//               <Grid item xs={12} style={{ position: 'relative' }}>
//                 <TextField
//                   error={!!this.state.errors.email}
//                   helperText={this.state.errors.email}
//                   style={{ marginBottom: '21px' }}
//                   variant="outlined"
//                   FormHelperTextProps={{
//                     style: {
//                       position: 'absolute',
//                       bottom: '-20px', // Adjust this value as needed
//                       left: 0,
//                     },
//                   }}
//                   // required
//                   fullWidth
//                   onChange={this.HandleInputChange}
//                   id="email"
//                   label="Email Address"
//                   name="email"
//                   value={this.state.Payload.email}
//                   autoFocus
//                   autoComplete="email"
//                   // validators={this.state.submitClicked ? ['required', 'isEmail'] : []}
//                   // errorMessages={[
//                   //   'This field is required',
//                   //   'Email is not valid',
//                   // ]}
//                   // errorMessages={
//                   //   this.state.submitClicked
//                   //     ? ['This field is required', 'Email is not valid']
//                   //     : ['']
//                   // }
//                 />
//               </Grid>
//               <Grid
//                 item
//                 xs={12}
//                 style={{ position: 'relative', paddingTop: '0px' }}
//               >
//                 <TextField
//                   error={!!this.state.errors.password}
//                   helperText={this.state.errors.password}
//                   variant="outlined"
//                   style={{ marginBottom: '21px' }}
//                   FormHelperTextProps={{
//                     style: {
//                       position: 'absolute',
//                       bottom: '-20px', // Adjust this value as needed
//                       left: 0,
//                     },
//                   }}
//                   // required
//                   fullWidth
//                   onChange={this.HandleInputChange}
//                   name="password"
//                   label="Password"
//                   type="password"
//                   id="password"
//                   value={this.state.Payload.password}
//                   autoComplete="current-password"
//                   // validators={['minStringLength:8', 'required']}
//                   // errorMessages={['8 Characters Minimum', 'This field is required']}
//                 />
//               </Grid>
//               <Grid item xs={12} style={{ paddingTop: '0px' }}>
//                 <FormControlLabel
//                   control={<Checkbox value="remember" color="primary" />}
//                   style={{ marginLeft: '0px' }}
//                   label="Remember me"
//                   name="remember"
//                   id="remember"
//                   className="rememberme"
//                   onChange={this.HandleCheckboxChange}
//                 />
//               </Grid>
//               <Grid item xs={12}>
//                 <Button
//                   type="submit"
//                   form="loginform"
//                   fullWidth
//                   variant="contained"
//                   color="primary"
//                   // onClick={this.HandleSubmit}
//                   className="loginsubmit"
//                 >
//                   Sign In
//                 </Button>
//               </Grid>
//             </Grid>
//             <Grid container>
//               <Grid item xs>
//                 <Link
//                   onClick={() => {
//                     navigate({
//                       pathname: '/password/forgot/',
//                     });
//                   }}
//                   style={{ cursor: 'pointer' }}
//                   variant="body1"
//                 >
//                   Forgot password?
//                 </Link>
//               </Grid>
//               <Grid item>
//                 <Link
//                   onClick={() => {
//                     navigate('/pricing/?d=Monthly');
//                   }}
//                   style={{ cursor: 'pointer' }}
//                   variant="body1"
//                 >
//                   Don't have an account? Sign Up
//                 </Link>
//               </Grid>
//             </Grid>
//           </ValidatorForm>
//           {/* </div> */}
//         </Container>
//       </Fragment>
//     );
//   }
// }

// Login.propTypes = {
//   DISPATCH_LOGIN: PropTypes.func.isRequired,
//   DISPATCH_CLEAR_AUTH: PropTypes.func.isRequired,
//   GET_ERRORS_STATE: PropTypes.array.isRequired,
//   GET_AUTH_STATE: PropTypes.object.isRequired,
//   // // t: PropTypes.func.isRequired,
//   // navigate: PropTypes.func.isRequired,
// };

// export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Login));
