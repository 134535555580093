import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import sweetAlert from 'sweetalert2';
import {connect} from 'react-redux';
import {Navigate} from 'react-router-dom';
import {withNamespaces} from 'react-i18next';
import {HTTP_REQUEST_EMAIL_CONFIRM} from 'app/services/httpClient/auth.service';
import {PROFILE_ACTION} from 'app/actions/profileAction';
import Grid from '@mui/material/Grid';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import {toast} from 'react-toastify';
import CssBaseline from '@mui/material/CssBaseline';
import Avatar from '@mui/material/Avatar';
import withRouter from 'app/utils/withRouter';

function mapStateToProps(state) {
  return {
    GET_AUTH_STATE: state.AUTH_REDUCER.Auth,
    GET_PROFILE_STATE: state.PROFILE_REDUCER.Profile,
    GET_ERRORS_STATE: state.ERROR_REDUCER.Errors,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    DISPATCH_PROFILE: (token) => dispatch(PROFILE_ACTION(token)),
  };
}

class Success extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Payload: {
        token: null,
      },
      GET_ERRORS_STATE: [],
      confirmed: false,
      message: '',
    };
    // bind event handlers in class components.
    this.HandleSubmit = this.HandleSubmit.bind(this);
  }

  componentDidMount() {
    const {search, navigate, GET_AUTH_STATE} = this.props;

    // const {navigate, GET_AUTH_STATE} = this.props;
    // if (GET_AUTH_STATE.isAuthenticated) {
    //   this.setState({
    //     message: 'Email confirmed. Please set up your account.',
    //   });
    // } else {
    //   this.setState({
    //     message: 'Email confirmed. Please sign into your account.',
    //   });
    // }
    // this.setState({
    //   Payload: {
    //     token: window.location.pathname.split('/')[2],
    //   },
    // });
    // const confirmpayload = {
    //   token: window.location.pathname.split('/')[2],
    // };
    // const response = await HTTP_REQUEST_EMAIL_CONFIRM(confirmpayload);
    // if (response.status === 200) {
    //   toast.success(this.state.message).then(navigate('/leadsearch'));
    // } else {
    //   toast.error('There was an issue confirming your email.');
    // }
  }

  componentDidUpdate(prevState) {}

  async HandleSubmit(event) {
    // event.preventDefault();
    // const emailToken = this.state.Payload;
    // const {DISPATCH_PROFILE, GET_AUTH_STATE} = this.props;
    // const response = await HTTP_REQUEST_EMAIL_CONFIRM(emailToken);
    // if (response.status === 200) {
    //   await sweetAlert.fire('Good job!', 'Your email has been confirmed successfully.', 'success');
    //   await DISPATCH_PROFILE(GET_AUTH_STATE.token);
    //   this.setState({confirmed: true});
    // } else {
    //   const {errors} = await response.json();
    //   this.setState({
    //     GET_ERRORS_STATE: Array.isArray(errors) ? [...errors] : [errors],
    //   });
    // }
  }

  render() {
    const {t} = this.props.search;
    if (this.props.search.m === 'free-trial') {
      return <Navigate to="/preconfirm/" />;
    } else if (t === 'n' || t === 'u') {
      return <Navigate to="/companies/" />;
    } else {
      return <Navigate to="/companies/" />;
    }
  }
}

Success.propTypes = {
  // DISPATCH_PROFILE: PropTypes.any.isRequired,
  // GET_AUTH_STATE: PropTypes.any.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Success));
